<template>
  <!-- 财富增值详情 -->
  <div class="increaseWealthDetails">
    <crumbs :item="title" />
    <div class="banner">
      <div class="banner_box">
        <div class="box_title">{{ productTitle }}</div>
        <p></p>
        <!-- <div class="provisions" v-for="item in banner" :key="item.id"> -->
        <el-carousel
          :interval="5000"
          :autoplay="true"
          arrow="never"
          trigger="click"
        >
          <el-carousel-item
            class="provisions"
            v-for="(item, index) in banner"
            :key="index"
          >
            <!-- <h3>{{ item }}</h3> -->
            <div
              ref="bannerItem"
              class="bannerItem"
              v-for="(items, index) in item"
              :key="items.zid"
            >
              <p>{{ items.basicCategoryName }}：</p>

              <!--              :class="[width100 ? 'hide' : 'show']"-->

              <p
                class="hide"
                :title="items.basicContent"
                @mouseenter="mouseenter(index)"
                @mouseout="mouseout(index)"
              >
                {{ items.basicContent }}
              </p>
              <!--              <el-tooltip-->
              <!--                class="item"-->
              <!--                effect="dark"-->
              <!--                :content="items.basicContent"-->
              <!--                placement="top-start"-->
              <!--                style="width: 300px"-->
              <!--              >-->

              <!--              </el-tooltip>-->
            </div>
          </el-carousel-item>
        </el-carousel>

        <!-- </div> -->
      </div>
    </div>

    <!-- tab栏 -->
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="产品特色" name="all"></el-tab-pane>
        <el-tab-pane label="保险责任" name="hot"></el-tab-pane>
        <el-tab-pane label="投保示例" name="marketing"></el-tab-pane>
        <el-tab-pane label="风险提示" name="generation"></el-tab-pane>
      </el-tabs>
    </div>
    <div class="richText" v-if="tab == 0">
      <Editor :readonly="true" v-if="content" :content="content" :height="0"></Editor>
      <empty v-else></empty>
    </div>
    <div class="richText" v-else-if="tab == 1">
      <Editor
        :readonly="true"
        v-if="data.productProfit"
        :content="data.productProfit"
        :height="0"
      ></Editor>
      <empty v-else></empty>
    </div>
    <div class="richText" v-else-if="tab == 2">
      <Editor
        :readonly="true"
        v-if="data.productExample"
        :content="data.productExample"
        :height="0"
      ></Editor>
      <empty v-else></empty>
    </div>
    <div class="richText" v-else-if="tab == 3">
      <Editor
        :readonly="true"
        v-if="data.productFanwei"
        :content="data.productFanwei"
        :height="0"
      ></Editor>
      <empty v-else></empty>
    </div>
    <div class="richText" v-else>
      <empty></empty>
    </div>
    <div class="obtain">
      <div class="obtain_btn" @click="obtain()">
        <div>获取一份专业投保建议书</div>
      </div>
    </div>
  </div>
</template>

<script>
import crumbs from "@/components/crumbs.vue";
import empty from "@/components/empty";
import { productListDetails } from "@/api/personal";
import Editor from "@/components/TinyMce";
export default {
  name: "increaseWealthDetails",
  data() {
    return {
      title: [],
      banner: [],
      activeName: "all",
      productTitle: "",
      content: "",
      tab: "",
      data: null,
      width100: true,
    };
  },
  created() {
    console.log(sessionStorage.getItem("DETAILS_ZID"));
    // this.zid = sessionStorage.getItem("DETAILS_ZID"); //产品页带过来的产品zid
    this.zid = this.$route.query.zid;
    console.log("this.$route.query.id",this.$route.query.zid)
    this.categoryType = sessionStorage.getItem("DETAILS_CATEGORYTYPE"); //产品页带过来的产品系列
    this.categoryName = sessionStorage.getItem("FALLBACK"); //产品页带过来的产品系列
    this.id = sessionStorage.getItem("FALLBACKID"); //产品页带过来的产品系列
    this.title = [
      {
        title: "产品中心",
        link: `/increaseWealth?index=0&categoryType=0`,
      },
      {
        title: this.categoryType == 0 ? "个人保险" : "热销产品",
        link: `/increaseWealth?index=0&categoryType=${this.categoryType}`,
        params: {},
      },
      {
        title: this.categoryName,
        link: `/increaseWealth?index=${this.id}&categoryType=${this.categoryType}`,
      },
      {
        title: "产品详情",
        link: `/increaseWealth/increaseWealthDetails`,
        params: { zid: this.zid },
      },
    ];
  },
  components: { crumbs, Editor, empty },
  mounted() {
    sessionStorage.setItem("increaseWealthInfo", true);
    this.getProductListDetails();

  },

  methods: {
    obtain() {
      this.$message.info("正在建设中");
    },
    mouseenter(e) {
      console.log(this.$refs.bannerItem);
      console.log("e", e);
      this.width100 = false;
    },
    mouseout() {
      // console.log("e", e);
      this.width100 = true;
    },
    handleClick(tab) {
      console.log(tab);
      this.tab = tab.index;
      console.log(this.tab);
    },
    async getProductListDetails() {
      try {
        const [timeStamp, sign] = this.$getSign();
        const res = await productListDetails({
          timeStamp,
          sign,
          zid: this.zid,
        });
        console.log("产品详情", res.data);
        if (res.data.code == 200) {
          this.data = res.data.data;
          this.content = res.data.data.productFeature;
          this.banner = res.data.data.productBasics;
          this.productTitle = res.data.data.productTitle;
          console.log(this.data);
        }
        if (
          res.data.code == 401 ||
          res.data.code == 404 ||
          res.data.code == 500
        ) {
          this.$router.replace("/found404");
        }
      } catch (error) {
        this.$message.error(error);
        console.log(error);
      }
    },
  },
  destroyed() {
    // sessionStorage.removeItem("_zid");
  },
};
</script>

<style lang="less" scoped>
.increaseWealthDetails {
  .banner {
    width: 100%;
    height: 23.4vw;
    background: #003781;

    .banner_box {
      width: 60%;
      height: 100%;
      margin: auto;
      color: #fff;
      padding: 1rem;

      > .box_title {
        font-size: 5rem;
        text-align: center;
        margin: 6rem 0;
      }

      > p {
        height: 1px;
        background: #f1f1f1;
      }

      .provisions {
        font-size: 1.5rem;
        margin: 5rem 0;

        > .bannerItem {
          display: flex;
          //justify-content: space-between;
          // align-items: center;
          height: 7rem;

          > p:first-child {
            color: #ffe795;
            white-space: nowrap;
          }

          > .hide {
            //width: 80%;
            height: 100%;
            overflow: hidden;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            //text-align: right;
            //white-space: nowrap;
            //overflow: hidden;
            //text-overflow: ellipsis;
            //cursor: pointer;
            animation: heightShow 0.3s ease-in 0s forwards;
          }

          > .show {
            text-align: right;
            width: 85%;
            height: 100%;

            &:hover {
              animation: heightShow 0.3s ease-in 0s forwards;
            }
          }
        }
      }

      /deep/ .el-carousel__container {
        position: relative;
        height: 12.5vw;
        overflow: hidden;
      }

      /deep/ .el-carousel__button {
        display: block;
        opacity: 0.48;
        width: 10px;
        height: 3px;
        background-color: #fff;
        border: none;
        outline: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        transition: 0.3s;
        border-radius: 0.5rem;
      }

      /deep/ .el-carousel__indicator.is-active button {
        opacity: 1;
        // background: #003781;
      }
    }
  }

  //获取
  .obtain {
    > .obtain_btn {
      > div {
        font-size: 1.8rem;
        white-space: nowrap;
      }

      width: 24rem;
      background-color: #003781;
      color: #fff;
      margin: 0rem auto 6rem;
      padding: 2rem;
      cursor: pointer;
      opacity: 0.6;
    }
  }

  .tabs {
    margin: 5rem 0 0rem;
  }

  .richText {
    width: 1034px;
    // height: 50vw;
    // border: 1px solid;
    margin: auto;
    //margin-bottom: 6rem;
  }

  /deep/ .el-tabs__nav-wrap::after {
    width: 0;
  }

  /deep/ .el-tabs__nav-scroll {
    overflow: hidden;
    display: flex;
    justify-content: center;
  }
  /deep/ .el-tabs__item {
    font-size: 2.5rem;
  }
}

::v-deep.el-breadcrumb {
  padding: 1.5rem 0;
  width: 80vw;
  margin: auto;
  font-size: 1.5rem;

  .el-breadcrumb__inner a:hover,
  .el-breadcrumb__inner.is-link:hover {
    color: #409eff !important;
    cursor: pointer;
  }
}

::v-deep.el-breadcrumb__item .el-breadcrumb__inner,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner a:hover,
::v-deep.el-breadcrumb__item .el-breadcrumb__inner:hover {
  font-weight: 400;
  color: #fff !important;
  cursor: pointer;
}

@keyframes heightShow {
  from {
    height: 0%;
  }
  to {
    height: 100%;
  }
}
</style>